import { Link } from "react-router-dom";

export default function Footer() {
  return (
    <>
      <div className="mt-40 text-xs text-center text-gray-500 dark:text-gray-400 lg:text-nowrap mb-4 px-4">
        <div className="flex space-x-4 justify-center mb-2">
          <Link to={`/company`} className="border-r pr-4 ">
            회사소개
          </Link>
          <Link to={`/privacy`} className="border-r pr-4 ">
            개인정보 처리방침
          </Link>
          <Link to={`/terms`} className="">
            이용약관
          </Link>
        </div>
        <div>
          서울특별시 관악구 은천동 903-2 광림빌딩 2F / 02-888-1623 / senwd.co.kr
          © 2015 (주)엑스비전테크놀로지 All right Reversed.
        </div>
      </div>
    </>
  );
}
