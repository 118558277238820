import { useEffect } from "react";

export default function NotFound() {
  useEffect(() => {
    if (window.matchMedia("(prefers-color-scheme: dark)").matches) {
      document.body.style.backgroundColor = "#0E1826";
    } else {
      document.body.style.backgroundColor = "#ffffff";
    }
  }, []);
  return (
    <div className="flex align-middle items-center mt-40 w-full dark:text-white">
      <div className="font-sanserif text-center mx-auto my-auto flex">
        <div className="font-bold text-xl my-auto">404</div>
        <div className="font-bold mx-4 my-auto">|</div>
        <div className="my-auto text-xl font-thin">PAGE NOT FOUND</div>
      </div>
    </div>
  );
}
