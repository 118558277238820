import axios from "axios";
import api from "../lib/api";

import { Link, Outlet, useLocation, useParams } from "react-router-dom";
import { useQueries } from "@tanstack/react-query";

export default function ChannelMain({ state }) {
  const location = useLocation();
  const params = useParams();

  const result = useQueries({
    queries: [
      {
        queryKey: [`ChannelBoardList`, params.channelId],
        queryFn: () =>
          axios
            .post(
              `/api/talkPageList/${params.channelId}`,
              {},
              {
                headers: {
                  Authorization: "bearer " + api.getToken(),
                },
              }
            )
            .then((res) => {
              return res.data.result;
            }),
      },
      {
        queryKey: ["ChannelInfo", params.channelId],
        queryFn: () =>
          axios
            .post(
              `/api/getTalkInfo/${params.channelId}`,
              {},
              {
                headers: {
                  Authorization: "bearer " + api.getToken(),
                },
              }
            )
            .then((res) => {
              return res.data.result;
            }),
      },
    ],
  });

  const boardList = result[0]?.data?.map((item, index) => {
    return (
      <div key={index}>
        <Link
          to={`/channel/${params.channelId}/${item.id}?page=1&size=10`}
          state={{
            channelTitle: location?.state?.channelTitle,
            boardTitle: item.name,
            boardAttributes: item.attributes,
            admin: location?.state?.admin || result[1]?.data?.admin,
          }}
        >
          <div
            className={
              Number(params.boardId) === item.id
                ? ` hover:bg-white py-3 hover:font-bold px-4 rounded-lg bg-yellow-300 font-bold  dark:text-black`
                : ` hover:bg-blue-500 py-3 hover:font-bold px-4 rounded-lg hover:text-white`
            }
          >
            {item.name}
          </div>
        </Link>
      </div>
    );
  });

  function JoinChannel() {
    // if (item.attributes.indexOf("JOIN") === -1) {
    //   window.alert("가입에 실패했습니다.");
    // } else {
    axios
      .post(
        `/api/joinTalk/${params.channelId}`,
        {},
        {
          headers: {
            Authorization: "bearer " + api.getToken(),
          },
        }
      )
      .then((res) => {
        // console.log(res);
        // if (Object.keys(res.data).length !== 0) {
        // navigate(`/channel/${item.id}`, {
        //   state: { channelTitle: item.title, admin: item.admin },
        // });
        // } else {
        //   window.alert("가입에 실패했습니다.");
        // }
        result[0].refetch();
        result[1].refetch();
      });
  }

  return (
    <>
      {result[0].isLoading || result[1].isLoading ? (
        "loading..."
      ) : (
        <>
          <div className="w-full">
            <div className=" bg-gray-200 w-full dark:bg-gray-800">
              <div className="w-3/4 flex mx-auto items-center ">
                <div className="font-bold text-blue-600 my-2 pr-12 text-nowrap dark:text-yellow-500">
                  <Link to={"."}>{result[1].data.title}</Link>
                </div>
                <div className="flex flex-wrap dark:text-gray-200">
                  {boardList}
                </div>
              </div>
            </div>

            <div className="w-full ">
              <div className="xl:w-8/12 w-10/12 mx-auto mt-20 ">
                <div className="text-center dark:text-white">
                  {params.boardId === undefined && !!result[1].data.talkInfo ? (
                    "가입된 채널입니다."
                  ) : params.boardId === undefined ? (
                    !!result[1]?.data?.attributes &&
                    result[1]?.data?.attributes.indexOf("JOIN") !== -1 ? (
                      <div
                        className="cursor-pointer border rounded-lg border-blue-600 p-2 text-blue-600 w-40 mx-auto hover:bg-blue-600 hover:text-white"
                        onClick={() => JoinChannel()}
                      >
                        채널 가입하기
                      </div>
                    ) : (
                      "가입할 수 없는 채널입니다."
                    )
                  ) : (
                    ""
                  )}
                </div>
                <Outlet />
              </div>
            </div>
          </div>
          {/* <div className=" mx-auto flex lg:w-3/4 mt-8">
            <div className="px-8 p-2 mr-auto lg:px-0">
              <BackButton />
            </div>
          </div> */}
        </>
      )}
    </>
  );
}
