import axios from "axios";

import { useRecoilValue } from "recoil";
import { idAtom, levelAtom, tokenAtom, userIdAtom } from "../lib/atom";
import { useState } from "react";

import ReplyEdit from "./ReplyEdit";
import {
  PaperClipIcon,
  PencilIcon,
  XMarkIcon,
} from "@heroicons/react/24/outline";
import { printTime } from "../lib/print_text";
import { useQuery } from "@tanstack/react-query";
import api from "../lib/api";
import ReplyWrite from "./ReplyWrite";

export default function ReplyList({ id, attribute, admin }) {
  const [editMode, setEditMode] = useState(false);
  const [editNum, setEditNum] = useState();
  const token = useRecoilValue(tokenAtom);
  const userId = useRecoilValue(idAtom);

  const { isLoading, error, data, refetch } = useQuery({
    queryKey: ["ReplyList", id],
    queryFn: () =>
      axios
        .post(
          `/api/getTalkItemCommentList/${id}`,
          {},
          {
            headers: {
              Authorization: "bearer " + api.getToken(),
            },
          }
        )
        .then((res) => {
          return res.data.result;
        })
        .catch((res) => console.log(res)),
  });

  const remove = (commentId) => {
    if (window.confirm("댓글을 삭제하시겠습니까?")) {
      axios
        .post(
          `/api/deleteTalkItemComment/${commentId}`,
          {},
          {
            headers: {
              Authorization: "bearer " + api.getToken(),
            },
          }
        )
        .then((res) => {
          refetch();
        })
        .catch((res) => window.alert(res));
    }
  };

  const edit = (commentId) => {
    setEditMode(true);
    setEditNum(commentId);
  };

  function commentFileDown(id, index) {
    axios
      .get(`/api/downloadTalkPageItem/${id}`, {
        params: { comment: true, index },
      })
      .then((res) => {
        // console.log(res);
        return res;
      });
  }

  function filesList(list, id) {
    return list.map((item, index) => (
      <>
        {index !== 0 && `, `}
        <a
          href={`/api/downloadTalkPageItem/${id}?comment=true&index=${index}`}
          className="p-1 text-sm text-gray-500 cursor-pointer hover:text-blue-500"
        >
          {item.name}
        </a>
      </>
    ));
  }

  const itemList = data?.content.map((row, id) => {
    return (
      <tr
        key={row.id}
        className={
          id > 0
            ? `border-dashed border-t-stone-200 border-t dark:border-t-gray-700 text-sm leading-6 text-gray-800 dark:text-gray-400 hover:bg-gray-50 dark:hover:bg-gray-800 flex`
            : `text-sm leading-6 text-gray-800 dark:text-gray-400 hover:bg-gray-50 dark:hover:bg-gray-800 flex `
        }
      >
        <td className="my-auto py-2 px-4 font-bold w-2/12 text-gray-500  dark:text-gray-300 break-words ">
          {row.name}
        </td>
        <td className="my-auto py-2 sm:text-ellipsis flex-1 whitespace-pre-line dark:text-gray-300/80">
          {editMode && editNum === row.id ? (
            <ReplyEdit
              setEditMode={setEditMode}
              content={row.content}
              commentId={row.id}
              id={id}
              refetch={refetch}
            />
          ) : (
            <>
              {row.content}
              {(row.userId === userId || !!admin) && (
                <div className="inline-block space-x-2 ml-2 align-middle">
                  {row.userId === userId && (
                    <button onClick={() => edit(row.id)} title={"수정"}>
                      <PencilIcon className="h-4  text-gray-500 hover:border hover:border-gray-500" />
                    </button>
                  )}
                  <button onClick={() => remove(row.id)} title={"삭제"}>
                    <XMarkIcon className="h-4  text-red-500 hover:border hover:border-red-500" />
                  </button>
                </div>
              )}
              <div>
                {!!row.files && (
                  <div className="border-t border-dashed  border-gray-200 w-full">
                    <PaperClipIcon className="h-4 text-gray-500 mr-2 inline-block " />
                    {filesList(row.files.files, row.id)}
                  </div>
                )}
              </div>
            </>
          )}
        </td>
        <td className="my-auto py-2 px-4 text-right text-gray-500 text-xs w-2/12">
          {printTime(row.createTime, false)}
        </td>
      </tr>
    );
  });

  return (
    <>
      {isLoading ? (
        <tr>
          <td className="text-xs text-center">Loading...</td>
        </tr>
      ) : error ? (
        <tr>
          <td>Error: {error.message}</td>
        </tr>
      ) : (
        <>
          <tr>
            <td className="pb-2 pt-12 pl-4 border-b text-sm font-bold text-gray-500 dark:border-b-gray-600 dark:text-gray-400">
              댓글
            </td>
          </tr>
          {itemList}
          {!!token && attribute.includes("COMMENT") && (
            <ReplyWrite itemId={id} refetch={refetch} attribute={attribute} />
          )}
        </>
      )}
    </>
  );
}
