import { useEffect, useRef } from "react";
import { useSearchParams } from "react-router-dom";

export default function SearchTypeBar() {
  const [searchParams, setSearchParams] = useSearchParams();
  const currentParams = Object.fromEntries([...searchParams]);
  const searchText = useRef();
  const searchType = useRef();
  const searchSize = useRef();

  function handleSubmit(e) {
    e.preventDefault();
    currentParams.search = searchText.current.value;
    currentParams.type = searchType.current.value;
    currentParams.page = 1;
    currentParams.size = searchSize.current.value;
    setSearchParams(currentParams);
  }

  useEffect(() => {
    searchText.current.value = currentParams.search || "";
    searchSize.current.value = currentParams.size;
    searchType.current.value = currentParams.type || 0;
  }, [currentParams]);

  return (
    <form onSubmit={handleSubmit}>
      <select
        name="size"
        ref={searchSize}
        className="h-10 focus:outline-none focus:ring-2 focus:ring-blue-500 mr-1 border border-[#e5e7eb] dark:bg-gray-700 dark:text-white dark:border-gray-600"
      >
        <option value={5}>5건</option>
        <option value={10}>10건</option>
        <option value={15}>15건</option>
        <option value={20}>20건</option>
      </select>
      <select
        name="type"
        ref={searchType}
        className="h-10 focus:outline-none focus:ring-2 focus:ring-blue-500 mr-1 border border-[#e5e7eb] dark:bg-gray-700 dark:text-white dark:border-gray-600"
      >
        <option value={0}>제목</option>
        <option value={1}>이름</option>
        <option value={2}>별명</option>
        <option value={3}>내용</option>
        <option value={4}>제목+내용</option>
      </select>
      <label className="">
        <input
          id="search"
          type="search"
          ref={searchText}
          name="keyword"
          className="p-2 py-2.5 border box-content bg-white 
          border-slate-300 text-sm shadow-sm placeholder-slate-400
          focus:outline-none  focus:ring-1 focus:ring-sky-500 
          disabled:bg-slate-50 disabled:text-slate-600 disabled:border-slate-200 disabled:shadow-none dark:bg-gray-700 dark:border-gray-500 dark:text-white"
          placeholder="검색어를 입력하세요"
        />
      </label>
      <button
        type="submit"
        className="hover:dark:bg-blue-500  bg-gray-500 p-2 px-4 text-gray-100 font-bold hover:bg-stone-600 border-gray-500 border hover:border-stone-500"
      >
        검색
      </button>
    </form>
  );
}
