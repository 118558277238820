export default function Terms() {
  return (
    <>
      <div className="w-full sm:w-3/4 sm:mx-auto mt-24">
        <div className="my-16 text-2xl font-bold text-center text-blue-600 dark:text-blue-500">
          이용약관
        </div>
        <div className="p-4 lg:w-1/2 mx-auto dark:text-gray-300">
          <p className="mb-4">
            센스월드 이용약관은 다음과 같은 내용을 담고 있습니다.
          </p>

          <p className="mb-4">제 1 조 (목적)</p>
          <p className="mb-4">
            이 약관은 IT로열린도서관(이하 '도서관')이 제공하는 센스월드 및
            리드애니, 링크애니 서비스의 이용과 관련하여 도서관과 회원과의 권리,
            의무 및 책임사항, 기타 필요한 사항을 규정함을 목적으로 합니다.
          </p>
          <p className="mb-4">제 2 조 (정의)</p>
          <p className="mb-4">
            이 약관에서 사용하는 용어의 정의는 다음과 같습니다.
          </p>

          <p className="mb-1">
            ① 서비스: PC 또는 모바일 장치를 이용하여 '회원'이 이용할 수 있는
            센스월드 서비스, 리드애니 서비스, 링크애니 서비스를 말합니다.
          </p>
          <p className="mb-1">
            ② 가입신청자: '서비스' 이용을 위하여 회원가입을 신청한 고객을
            말합니다.
          </p>
          <p className="mb-1">
            ③ 회원: 도서관의 '서비스'에 접속하여 이 약관에 따라 '도서관'과
            이용계약을 체결하고 '도서관'이 제공하는 '서비스'를 이용하는
            '정회원'과 '준회원'을 말합니다.
          </p>
          <p className="mb-1">
            ④ 정회원: 모든 '서비스'를 재한없이 이용 가능한 고객을 말합니다.
          </p>
          <p className="mb-1">
            ⑤ 준회원: '서비스'의 기능 중 링크애니(메시지, 채널) 서비스 기능만을
            이용 가능한 고객을 말합니다.
          </p>
          <p className="mb-1">
            ⑥ 아이디(ID): '회원'의 식별과 '서비스' 이용을 위하여 '회원'이 정하고
            '도서관'이 승인하는 문자 또는 문자, 숫자의 조합을 의미합니다.
          </p>
          <p className="mb-1">
            ⑦ 비밀번호: '회원'이 부여 받은 '아이디와 일치되는 '회원'임을
            확인하고 비밀보호를 위해 '회원' 자신이 정한 문자, 숫자의 조합을
            의미합니다.
          </p>
          <p className="mb-1">
            ⑧ 게시물: '회원'이 '서비스'를 이용함에 있어 공개 채널에 게시한
            부호ㆍ문자ㆍ음성ㆍ음향ㆍ화상ㆍ동영상 등의 정보 형태의 글, 사진,
            동영상 및 각종 파일과 링크 등을 의미합니다.
          </p>
          <p className="mb-4">
            ⑨ 자료: '정회원'이 '서비스'를 이용함에 있어 공개 채널과 공유
            드라이브에 등록한 부호ㆍ문자ㆍ음성ㆍ음향ㆍ화상ㆍ동영상 등의 정보
            형태의 글, 사진, 동영상 및 각종 파일과 링크 등을 의미합니다.
          </p>

          <p className="mb-4">제 3 조 (약관의 게시와 개정)</p>

          <p className="mb-1">
            ① '도서관'은 이 약관의 내용을 '회원'이 쉽게 알 수 있도록 '도서관'의
            게시판에 게시합니다.
          </p>
          <p className="mb-1">
            ② '도서관'은 '약관의규제에관한법률',
            '정보통신망이용촉진및정보보호등에관한법률(이하 '정보통신망법')' 등
            관련법을 위배하지 않는 범위에서 이 약관을 개정할 수 있습니다.
          </p>
          <p className="mb-1">
            ③ '도서관'이 약관을 개정할 경우에는 적용일자 및 개정사유를 명시하여
            현행약관과 함께 제1항의 방식에 따라 그 개정약관의 적용일자 30일
            전부터 적용일자 전일까지 공지합니다. 다만, '회원'에게 불리한 약관의
            개정의 경우에는 공지 외에 일정기간 서비스내 전자우편, 로그인시
            동의창 등의 전자적 수단을 통해 따로 명확히 통지하도록 합니다.
          </p>
          <p className="mb-1">
            ④ '도서관'이 전항에 따라 개정약관을 공지 또는 통지하면서 '회원'에게
            30일 기간 내에 의사표시를 하지 않으면 의사표시가 표명된 것으로
            본다는 뜻을 명확하게 공지 또는 통지하였음에도 '회원'이 명시적으로
            거부의 의사표시를 하지 아니한 경우 '회원'이 개정약관에 동의한 것으로
            봅니다.
          </p>
          <p className="mb-4">
            ⑤ '회원'이 개정약관의 적용에 동의하지 않는 경우 '도서관'은 개정
            약관의 내용을 적용할 수 없으며, 이 경우 '회원'은 이용계약을 해지할
            수 있습니다. 다만, 기존 약관을 적용할 수 없는 특별한 사정이 있는
            경우에는 '도서관'은 이용계약을 해지할 수 있습니다.
          </p>

          <p className="mb-4">제 4 조 (이용계약 체결)</p>

          <p className="mb-1">
            ① 이용계약은 '회원'이 되고자 하는 자(이하 '가입신청자')가 약관의
            내용에 대하여 동의를 한 다음 회원가입신청을 하고 '도서관'이 이러한
            신청에 대하여 승낙함으로써 체결됩니다.
          </p>
          <p className="mb-1">
            ② '도서관'은 '가입신청자'의 신청에 대하여 '서비스' 이용을 승낙함을
            원칙으로 합니다.
          </p>
          <p className="mb-1">
            ③ '도서관'은 '가입신청자'가 센스월드에서 제공하는 콘텐츠 서비스
            이용을 위해 신청한 경우 '장애인증명서' 또는 '장애인복지카드' 사본을
            제출 받아 장애인복지법'상 등록 시각장애인임을 확인할 수 있습니다.
          </p>
          <p className="mb-1">
            ④'도서관'은 다음 각 호에 해당하는 신청에 대하여는 승낙을 하지 않거나
            사후에 이용계약을 해지할 수 있습니다.
          </p>

          <p className="mb-1 ml-4">
            1. '가입신청자'가 이 약관에 의하여 이전에 회원자격을 상실한 적이
            있는 경우, 다만, 회원자격 상실 후 1년이 경과한 자로서 '도서관'의
            회원 재가입 승낙을 얻은 경우에는 예외로 함.
          </p>
          <p className="mb-1 ml-4">
            2. 실명이 아니거나 타인의 명의를 이용한 경우
          </p>
          <p className="mb-1 ml-4">
            3. 등록 내용에 허위, 기재누락, 오기가 있는 경우 또는 '도서관'이
            제시하는 내용을 기재하지 않은 경우
          </p>
          <p className="mb-1 ml-4">
            4. 14세 미만 아동이 법정대리인(부모 등)의 동의를 얻지 아니한 경우
          </p>
          <p className="ml-4">
            5. 이용자의 귀책사유로 인하여 승인이 불가능하거나 기타 규정한 제반
            사항을 위반하며 신청하는 경우
          </p>

          <p className="mb-1">
            ⑤ 제1항에 따른 신청에 있어 '도서관'은 '회원'의 정보가 불충분할 경우
            전문기관을 통한 실명확인 및 본인인증을 요청할 수 있습니다.
          </p>
          <p className="mb-1">
            ⑥ '도서관'은 서비스관련설비의 여유가 없거나, 기술상 또는 업무상
            문제가 있는 경우에는 승낙을 유보할 수 있습니다.
          </p>
          <p className="mb-1">
            ⑦ 제4항과 제6항에 따라 회원가입신청의 승낙을 하지 아니하거나 유보한
            경우, '도서관'은 원칙적으로 이를 '가입신청자'에게 알리도록 합니다.
          </p>
          <p className="mb-1">
            ⑧ 이용계약의 성립 시기는 '도서관'이 가입완료를 신청절차 상에서
            표시한 시점으로 합니다.
          </p>
          <p className="mb-1">
            ⑨ '도서관'은 '회원'에 대해 도서관 정책에 따라 서비스 메뉴 등을
            세분하여 일부 서비스 제한 등 이용에 차등을 둘 수 있습니다.
          </p>
          <p className="mb-4">
            ⑩ '도서관'은 '회원'에 대하여 '영화및비디오물의진흥에관한법률' 및
            '청소년보호법'등에 따른 연령 준수 여부에 따라 이용제한을 할 수
            있습니다.
          </p>

          <p className="mb-4">제 5 조 (회원정보의 변경)</p>

          <p className="mb-1">
            ① '회원'은 정보관리화면을 통하여 언제든지 본인의 정보를 열람하고
            수정할 수 있습니다. 다만, 서비스 관리를 위해 필요한 실명, 생년월일,
            아이디 등은 수정이 불가능합니다.
          </p>
          <p className="mb-1">
            ② '회원'은 회원가입신청 시 기재한 사항이 변경되었을 경우 온라인으로
            수정을 하거나 전화 기타 방법으로 '도서관'에 대하여 그 변경사항을
            알려야 합니다.
          </p>
          <p className="mb-4">
            ③ 제2항의 변경사항을 '도서관'에 알리지 않아 발생한 불이익에 대하여
            '도서관'은 책임지지 않습니다.
          </p>

          <p className="mb-4">제 6 조 (개인정보보호 의무)</p>

          <p className="mb-4">
            ① '도서관'은 '정보통신망법' 등 관계 법령이 정하는 바에 따라 '회원'의
            개인정보를 보호하기 위해 노력합니다. 개인정보의 보호 및 사용에
            대해서는 관련법 및 '도서관'의 개인정보취급방침이 적용됩니다.
          </p>

          <p className="mb-4">
            제 7 조 ('회원'의 '아이디' 및 '비밀번호'의 관리에 대한 의무)
          </p>

          <p className="mb-1">
            ① '회원'의 '아이디'와 '비밀번호'에 관한 관리책임은 '회원'에게
            있으며, 이를 제3자가 이용하도록 하여서는 안 됩니다.
          </p>
          <p className="mb-1">
            ② '도서관'은 '회원'의 '아이디'가 개인정보 유출 우려가 있거나,
            반사회적 또는 미풍양속에 어긋나거나 '도서관' 및 '도서관'의 운영자로
            오인할 우려가 있는 경우, 해당 '아이디'의 이용을 제한할 수 있습니다.
          </p>
          <p className="mb-1">
            ③ '회원'은 '아이디' 및 '비밀번호'가 도용되거나 제3자가 사용하고
            있음을 인지한 경우에는 이를 즉시 '도서관'에 통지하고 '도서관'의
            안내에 따라야 합니다.
          </p>
          <p className="mb-4">
            ④ 제3항의 경우에 해당 '회원'이 '도서관'에 그 사실을 통지하지 않거나,
            통지한 경우에도 '도서관'의 안내에 따르지 않아 발생한 불이익에 대하여
            '도서관'은 책임지지 않습니다.
          </p>

          <p className="mb-4">제 8 조 ('회원'에 대한 통지)</p>

          <p className="mb-1">
            ① '도서관'이 '회원'에 대한 통지를 하는 경우 이 약관에 별도 규정이
            없는 한 개인정보상의 전화번호, 전자우편 또는 '서비스'상의 메시지
            등으로 할 수 있습니다.
          </p>
          <p className="mb-4">
            ② '도서관'은 '회원' 전체에 대한 통지의 경우 7일 이상 '도서관'의
            게시판에 게시함으로써 제1항의 통지에 갈음할 수 있습니다.
          </p>

          <p className="mb-4">제 9 조 ('도서관'의 의무)</p>

          <p className="mb-1">
            ① '도서관'은 관련법과 이 약관이 금지하거나 미풍양속에 반하는 행위를
            하지 않으며, 계속적이고 안정적으로 서비스를 제공하기 위하여 최선을
            다하여 노력합니다.
          </p>
          <p className="mb-1">
            ② '도서관'은 '회원'이 안전하게 서비스를 이용할 수 있도록 개인정보
            보호를 위해 보안시스템을 갖추어야 하며 개인정보취급방침을 공시하고
            준수합니다.
          </p>
          <p className="mb-1">
            ③ '도서관'은 서비스이용과 관련하여 발생하는 이용자의 불만 또는
            피해구제요청을 적절하게 처리할 수 있도록 필요한 인력 및 시스템을
            구비합니다.
          </p>
          <p className="mb-4">
            ④ '도서관'은 서비스이용과 관련하여 '회원'으로부터 제기된 의견이나
            불만이 정당하다고 인정할 경우에는 이를 처리하여야 합니다. '회원'이
            제기한 의견이나 불만사항에 대해서는 '도서관'의 게시판을 활용하거나
            전자우편 등을 통하여 '회원'에게 처리과정 및 결과를 전달합니다.
          </p>

          <p className="mb-4">제 10 조 ('회원'의 의무)</p>

          <p className="mb-1">① '회원'은 다음 행위를 하여서는 안 됩니다.</p>

          <p className="mb-1 ml-4">
            1. 가입 신청 또는 정보 변경 시 허위내용의 등록
          </p>
          <p className="mb-1 ml-4">2. 타인의 정보도용</p>
          <p className="mb-1 ml-4">3. '도서관'이 게시한 정보의 변경</p>
          <p className="mb-1 ml-4">
            4. '도서관'이 정한 정보 이외의 정보(컴퓨터 프로그램 등) 등의 송신
            또는 게시
          </p>
          <p className="mb-1 ml-4">
            5. '도서관'과 기타 제3자의 저작권 등 지적재산권에 대한 침해
          </p>
          <p className="mb-1 ml-4">
            6. '도서관' 및 기타 제3자의 명예를 손상시키거나 업무를 방해하는 행위
          </p>
          <p className="mb-1 ml-4">
            7. 외설 또는 폭력적인 메시지, 화상, 음성, 기타 공서양속에 반하는
            정보를 '서비스'에 공개 또는 게시하는 행위
          </p>
          <p className="mb-1 ml-4">
            8. '도서관'의 동의 없이 영리를 목적으로 '서비스'를 사용하는 행위
          </p>
          <p className="mb-1 ml-4">9. 기타 불법적이거나 부당한 행위</p>

          <p className="mb-4">
            ② '회원'은 관계법, 이 약관의 규정, 이용안내 및 서비스와 관련하여
            공지한 주의사항, '도서관'이 통지하는 사항 등을 준수하여야 하며, 기타
            '도서관'의 업무에 방해되는 행위를 하여서는 안 됩니다.
          </p>

          <p className="mb-4">제 11 조 ('서비스'의 제공 등)</p>

          <p className="mb-1">
            ① '도서관'은 회원에게 아래와 같은 서비스를 제공합니다.
          </p>

          <p className="mb-1 ml-4">
            1. 메시지 서비스 : 부호 · 문자 · 음성 · 음향 · 화상 · 동영상 등의
            정보 형태의 글, 사진, 동영상 및 각종 파일과 링크 등을 '서비스'를
            이용하는 다른 '회원'에게 전송할 수 있는 서비스
          </p>
          <p className="mb-1 ml-4">
            2. 채널 서비스 : 개별 '회원' 또는 다수 '회원'이 필요에 의해 개설할
            수 있는 링크애니 내의 공간이며 단방향 또는 쌍방향 메시지 전송과
            전자게시판을 이용할 수 있는 서비스
          </p>
          <p className="mb-1 ml-4">
            3. 드라이브 서비스: '회원'이 개인 자료를 보관하는 개인 드라이브와
            소유한 자료를 공유할 수 있는 공유 드라이브 공간을 제공하는 서비스
          </p>
          <p className="mb-1 ml-4">
            4. 도서관 서비스: IT로열린도서관, 국립장애인도서관에 등록된
            데이지(DAISY)도서를 제공하는 서비스
          </p>
          <p className="mb-1 ml-4">
            5. 미디어 서비스: 팟캐스트, 유튜브, 실시간 방송, 뉴스 콘텐츠를
            제공하는 서비스
          </p>
          <p className="mb-1 ml-4">
            6. 기타 '도서관'이 추가 개발한 일체의 서비스
          </p>

          <p className="mb-1">
            ② '서비스'는 연중무휴, 1일 24시간 제공함을 원칙으로 합니다.
          </p>
          <p className="mb-1">
            ③ '도서관'은 컴퓨터 등 정보통신설비의 보수점검, 교체 및 고장,
            통신두절 또는 운영상 상당한 이유가 있는 경우 '서비스'의 제공을
            일시적으로 중단할 수 있습니다. 이 경우 '도서관'은 제8조['회원'에
            대한 통지]에 정한 방법으로 '회원'에게 통지합니다. 다만, '도서관'이
            사전에 통지할 수 없는 부득이한 사유가 있는 경우 사후에 통지할 수
            있습니다.
          </p>
          <p className="mb-4">
            ④ '도서관'은 서비스 제공에 필요한 경우 정기점검을 실시할 수 있으며,
            정기점검시간은 '도서관'의 게시판에 공지한 바에 따릅니다.
          </p>

          <p className="mb-4">제 12 조 ('서비스'의 변경)</p>

          <p className="mb-1">
            ① '도서관'은 상당한 이유가 있는 경우에 운영상, 기술상의 필요에 따라
            제공하고 있는 전부 또는 일부 서비스를 변경할 수 있습니다.
          </p>
          <p className="mb-1">
            ② 서비스의 내용, 이용방법, 이용시간에 대하여 변경이 있는 경우에는
            변경사유, 변경될 서비스의 내용 및 제공일자 등은 그 변경 전에
            '도서관'의 게시판에 게시하여야 합니다.
          </p>
          <p className="mb-4">
            ③ '도서관'은 무료로 제공되는 서비스의 일부 또는 전부를 '도서관'의
            정책 및 운영의 필요상 수정, 중단, 변경할 수 있으며, 이에 대하여
            관련법에 특별한 규정이 없는 한 '회원'에게 별도의 보상을 하지
            않습니다.
          </p>

          <p className="mb-4">제 13 조 ('게시물'의 저작권)</p>

          <p className="mb-4">
            ① '회원'이 '서비스' 내에 게시한 '게시물'의 저작권은 해당 게시물의
            저작자에게 귀속됩니다.
          </p>

          <p className="mb-4">제 14 조 ('게시물' 및 '자료'의 관리)</p>

          <p className="mb-1">
            ① '회원'의 '게시물'이나 '자료'가 '정보통신망법' 및 '저작권법'등
            관련법에 위반되는 내용을 포함하는 경우, 권리자는 관련법이 정한
            절차에 따라 해당 '게시물'의 게시중단 및 '자료' 삭제 등을 요청할 수
            있으며, '도서관'은 관련법에 따라 조치를 취하여야 합니다.
          </p>
          <p className="mb-1">
            ② '도서관'은 전항에 따른 권리자의 요청이 없는 경우라도 권리침해가
            인정될 만한 사유가 있거나 기타 회사 정책 및 관련법에 위반되는
            경우에는 관련법에 따라 해당 '게시물'과 '자료'에 대해 임시조치 등을
            취할 수 있습니다.
          </p>
          <p className="mb-1">
            ③ '회원'이 소유하고 있는 개인 드라이브의 모든 '자료'는 본인이
            관리하여야 하며, '도서관'은 이에 대한 백업을 보장하지 않습니다.
          </p>
          <p className="mb-1">
            ④ '도서관'은 '회원'이 개인 드라이브에 등록한 '자료'에 대해
            자료백업이나 그에 상당하는 행위를 하지 않아 '자료'의 손실이 발생한
            경우 책임을 지지 않습니다.
          </p>
          <p className="mb-1">
            ⑤ 개인 드라이브와 드라이브 다운로드 폴더는 동기화 되고 있으므로
            어떠한 '자료'가 변경 및 삭제되었을 경우 양쪽에 동일하게 적용됩니다.
            '회원'은 '자료'의 변경이나 삭제를 할 경우 '회원'의 잘못된 판단을
            방지하기 위한 조치로 개인 저장공간에 '자료'의 백업을 반드시 해야
            합니다. 자료백업이나 그에 상당하는 행위를 하지 않아 '자료'의 손실이
            발생한 경우 '도서관'은 책임을 지지 않습니다.
          </p>
          <p className="mb-1">
            ⑥ '회원'의 자료백업 도중 발생한 '자료'의 변형 및 삭제에 대해서는
            '도서관'은 책임을 지지 않습니다.
          </p>
          <p className="mb-4">
            ⑦ 본 조에 따른 세부절차는 '정보통신망법' 및 '저작권법'이 규정한 범위
            내에서 '도서관'이 정한 규칙에 따릅니다.
          </p>

          <p className="mb-4">제 15 조 (권리의 귀속)</p>

          <p className="mb-4">
            ① '서비스'에 대한 저작권 및 지적재산권은 '도서관'에 귀속됩니다. 단,
            '회원'의 '게시물' 저작물 등은 제외합니다.
          </p>

          <p className="mb-4">제 16 조 (계약해제, 해지 등)</p>

          <p className="mb-1">
            ① '회원'은 언제든지 '서비스' 내의 회원탈퇴 메뉴를 통하여 이용계약
            해지 신청을 할 수 있으며, '도서관'은 관련법 등이 정하는 바에 따라
            이를 즉시 처리하여야 합니다. 다만 이 경우 1년 동안 재가입할 수
            없습니다.
          </p>
          <p className="mb-1">
            ② '회원'이 계약을 해지할 경우, 관련법 및 개인정보취급방침에 따라
            '도서관'이 회원정보를 보유하는 경우를 제외하고는 해지 즉시 '회원'의
            모든 데이터는 소멸됩니다.
          </p>
          <p className="mb-4">
            ③ '회원'이 계약을 해지하는 경우, '회원'이 개설한 채널의 일체는
            삭제됩니다. 다만, 타인에 의해 스크랩 등이 되어 재게시된
            '게시물'이나, 회원이 개설하지 않은 채널에 등록된 '게시물' 등은
            삭제되지 않으니 사전에 삭제 후 탈퇴하시기 바랍니다.
          </p>

          <p className="mb-4">제 17 조 (이용제한 등)</p>

          <p className="mb-1">
            ① '도서관'은 '회원'이 이 약관의 의무를 위반하거나 서비스의 정상적인
            운영을 방해한 경우, 경고, 일시정지, 영구이용정지 등으로 서비스
            이용을 단계적으로 제한할 수 있습니다.
          </p>
          <p className="mb-1">
            ② '도서관'은 전항에도 불구하고, '주민등록법'을 위반한 명의도용
            '저작권법'을 위반한 불법프로그램의 제공 및 운영방해,
            '정보통신망법'을 위반한 불법통신 및 해킹, 악성프로그램의 배포,
            접속권한 초과행위 등과 같이 관련법을 위반한 경우에는 즉시
            영구이용정지를 할 수 있습니다. '도서관'은 이에 대해 별도로 보상하지
            않습니다.
          </p>
          <p className="mb-1">
            ③ '도서관'은 도서관 정책에 따라 하나의 명의로 하나의 '아이디'만을
            발급합니다. 만일 '도서관'이 하나의 명의로 가입된 다수의 '아이디'를
            발견한 경우에는 하나의 '아이디'를 제외한 나머지 '아이디'에 관하여는
            이용을 제한할 수 있습니다. 이 경우 '도서관'은 제8조['회원'에 대한
            통지]에 정한 방법으로 '회원'에게 통지합니다.
          </p>
          <p className="mb-1">
            ④ '도서관'은 '회원'이 계속해서 6개월 이상 로그인하지 않는 경우,
            회원정보의 보호 및 운영의 효율성을 위해 이용을 제한할 수 있습니다.
          </p>
          <p className="mb-1">
            ⑤ '도서관'은 본 조의 이용제한 범위 내에서 제한의 조건 및 세부내용은
            이용제한정책 및 개별 서비스상의 이용약관에서 정하는 바에 의합니다.
          </p>
          <p className="mb-1">
            ⑥ 본 조에 따라 서비스 이용을 제한하거나 계약을 해지하는 경우에는
            '도서관'은 제8조['회원'에 대한 통지]에 따라 통지합니다.
          </p>
          <p className="mb-4">
            ⑦ '회원'은 본 조에 따른 이용제한 등에 대해 '도서관'이 정한 절차에
            따라 이의신청을 할 수 있습니다. 이 때 이의가 정당하다고 '도서관'이
            인정하는 경우 '도서관'은 즉시 서비스의 이용을 재개합니다.
          </p>

          <p className="mb-4">제 18 조 (이용제한 사유에 해당되는 자료)</p>

          <p className="mb-1">
            ① '도서관'은 원활한 서비스의 제공을 위해 필요한 경우 공유 드라이브에
            등록된 '자료'를 모니터링 할 수 있으며, 본 이용약관에서 금지한
            '자료'를 발견한 경우 해당 '자료'를 임의로 삭제하거나 그 당사자에
            대해서는 이용정지, 회원 탈퇴 등의 이용제한 조치를 할 수 있습니다.
          </p>
          <p className="mb-1">
            ② '회원'은 어떠한 경우에도 본 이용약관에서 금지한 '자료'를 공유할 수
            없으며 이를 위반하여 발생하는 모든 책임은 '자료'를 공유한 '회원'에게
            있습니다.
          </p>
          <p className="mb-1">
            ③ '도서관'은 다음 각 호에 해당하는
            '자료'(문서ㆍ음성ㆍ음향ㆍ사진ㆍ동영상 및 각종 파일과 링크 포함)에
            대해서는 공유를 금지합니다.
          </p>

          <p className="mb-1 ml-4">1. 상용자료</p>
          <p className="mb-1 ml-8">
            - 현재 국내에 판매되고 있거나 과거 판매된 적이 있는 자료
          </p>
          <p className="mb-1 ml-8">
            - 국내에 해당 자료의 라이선스를 가진 업체가 있을 경우
          </p>
          <p className="mb-1 ml-8">
            - 국내에 판매 및 라이선스 계약을 맺은 업체가 없더라도 외국에서
            판매되고 있는 자료
          </p>
          <p className="mb-1 ml-4">2. 저작권 보호자료</p>
          <p className="mb-1 ml-8">- 저작권자가 배포를 원하지 않는 자료</p>
          <p className="mb-1 ml-8">
            - 셰어웨어나 데모 프로그램의 기능제한을 제거하는 크랙 및 패치자료
          </p>
          <p className="mb-1 ml-4">3. 손상자료</p>
          <p className="mb-1 ml-8">- 바이러스, 악성코드 등에 감염된 자료</p>
          <p className="mb-1 ml-8">
            - 고의적, 악의적인 배치파일 또는 배치파일을 변환한 실행 파일이
            포함된 자료
          </p>
          <p className="mb-1 ml-4">4. 음란자료</p>
          <p className="mb-1 ml-8">
            - 사회에서 일반적으로 통용되는 수준을 넘는 과도한 노출이나 내용을
            가지고 있는 자료
          </p>
          <p className="mb-1 ml-4">5. 폭력물 및 명예훼손에 관한 자료</p>
          <p className="mb-1 ml-8">- 과도한 폭력적인 내용을 가지고 있는 자료</p>
          <p className="mb-1 ml-8">
            - 타인의 명예를 훼손하거나 모욕적인 내용을 가지고 있는 자료
          </p>
          <p className="mb-1 ml-4">
            6. 개별 법률과 본 이용약관에서 정한 매매, 유통 금지 물품의 판매 및
            알선에 대한 자료
          </p>
          <p className="mb-1 ml-4">7. 기타 부적격 자료</p>
          <p className="mb-1 ml-8">
            - 정보통신망 이용촉진 및 정보보호 등에 관한 법률 제44조의 7항에
            위배되는 내용의 자료
          </p>
          <p className="ml-8 mb-4">
            - 기타 이용약관에 위배되는 내용의 자료 및 센스월드 회원이나
            '도서관'에 불이익을 끼치는 것으로 '도서관'이 판단하는 자료
          </p>

          <p className="mb-4">제 19 조 (이용제한 사유에 해당되는 활동)</p>

          <p className="ml-4 mb-1">1. 불법 사행성, 도박 사이트를 홍보</p>
          <p className="ml-4 mb-1">
            2. 불법 제품 또는 인터넷에서 판매 금지된 물품을 판매하거나 홍보
          </p>
          <p className="ml-4 mb-1">
            3. 범법 행위에 대한 동기 부여 및 실행에 도움이 되는 정보를 제공
          </p>
          <p className="ml-4 mb-1">
            4. 악성코드, 바이러스 등의 프로그램을 설치/유포하여 '회원'의
            정상적인 센스월드 서비스 이용을 저해하거나, '회원'의 개인정보를
            탈취하려고하는 경우
          </p>
          <p className="ml-4 mb-1">
            5. 방송, 음원, 영화, 만화 등 타인의 저작물을 당사자의 동의 없이 공유
          </p>
          <p className="ml-4 mb-1">
            6. 타인의 저작물을 불법적인 경로로 획득할 수 있는 정보나 방법을 제공
            (예:무료 다운로드, 프리서버, CD키 공유 등)
          </p>
          <p className="ml-4 mb-1">
            7. 타인의 권리에 속하는 상표권, 의장권 등을 무단으로 침해
          </p>
          <p className="ml-4 mb-1">
            8. 타인의 개인정보를 포함하고 있는 내용을 공유
          </p>
          <p className="ml-4 mb-1">
            9. 타인의 개인정보 및 계정, 기기를 도용/탈취하여 '서비스'를
            가입하거나, 이용하는 경우
          </p>
          <p className="ml-4 mb-1">
            10. 과도한 신체 노출이나 음란한 행위를 묘사
          </p>
          <p className="ml-4 mb-1">11. 성매매 관련 정보를 공유</p>
          <p className="ml-4 mb-1">
            12. 타인에게 성적 수치심이나 불쾌감을 유발할 수 있는 내용을 공유
          </p>
          <p className="ml-4 mb-1">
            13. 일반적인 사람이 보기에 혐오스럽고 눈살이 찌푸려지는 사진 또는
            내용을 공유
          </p>
          <p className="ml-4 mb-1">
            14. 성별, 종교, 장애, 연령, 사회적 신분, 인종, 지역, 직업 등을
            차별하거나 이에 대한 편견을 조장하는 내용을 공유
          </p>
          <p className="ml-4 mb-1">
            15. 계정 및 계정 내 콘텐츠를 타인에게 판매, 양도, 대여하거나,
            타인에게 그 이용을 허락 또는 이를 시도하는 행위
          </p>
          <p className="ml-4 mb-1">
            16. 타인의 계정 및 계정 내 콘텐츠를 취득하기 위해 구매, 양수, 교환을
            시도하거나, 이를 타인에게 알선하는 활동
          </p>
          <p className="ml-4 mb-1">
            17. 타인을 기망하여 타인의 계정 및 계정 내 콘텐츠를 탈취하는 행위
          </p>
          <p className="ml-4 mb-1">
            18. 서비스 명칭 또는 '도서관' 임직원이나 '서비스'와 관련한 운영진을
            사칭하여 다른 '회원'을 속이거나 이득을 취하는 등 피해와 혼란을 주는
            행위
          </p>
          <p className="ml-4 mb-1">
            19. 욕설/비속어/은어 등 통상적인 금기어 사용과 그 외 '도서관'과
            '회원'이 공유하는 상식과 사회 통념에 반하는 비정상적인 활동
          </p>
          <p className="ml-4 mb-4">
            20. 위의 항목에 구체적으로 해당하지 않는 사항이라 하더라도 건전한
            서비스 환경 제공에 악영향을 끼치는 경우는 센스월드 이용약관에
            의거하여 이용이 제한될 수 있으며, 위반 활동이 반복되는 경우
            가중처리될 수 있습니다.
          </p>

          <p className="mb-4">제 20 조 (이용제한 사유에 해당하는 채널)</p>

          <p className="ml-4 mb-1">
            1. 타인의 개인정보(성명, 주민번호, 주소, 사진, 영상 등에 의하여
            특정한 개인을 알아볼 수 있는 정보)를 포함하는 채널
          </p>
          <p className="ml-4 mb-1">
            2. 다른 이용자 또는 제 3자에게 심한 모욕을 주거나 명예를 손상시키는
            정보를 포함하는 채널
          </p>
          <p className="ml-4 mb-1">
            3. 공공질서 및 미풍양속에 위반되는 내용을 포함하는 채널
          </p>
          <p className="ml-4 mb-1">
            4. 불법복제 또는 해킹을 조장하는 내용을 포함하는 채널
          </p>
          <p className="ml-4 mb-1">
            5. 범죄와 결부된다고 객관적으로 인정되는 내용을 포함하는 채널
          </p>
          <p className="ml-4 mb-1">
            6. 다른 이용자 또는 제 3자의 저작권 등 기타 권리를 침해하는 내용을
            포함하는 채널
          </p>
          <p className="ml-4 mb-1">
            7. 현행법률에서 인터넷을 통한 매매를 금지 및 제한하고 있는 품목의
            내용을 포함하는 채널
          </p>
          <p className="ml-4 mb-1">
            8. '도서관'에서 규정한 센스월드 이용약관에 어긋나는 채널
          </p>
          <p className="ml-4 mb-4">
            9. 기타 관계법령에 위배된다고 객관적으로 판단되는 채널
          </p>

          <p className="mb-4">제 21 조 (메시지의 백업과 보관 기간)</p>

          <p className="mb-1">
            ① 센스월드 내에서 송신 또는 수신한 메시지의 보관 기간은 5일이며 보관
            기간이 경과할 경우 메시지는 서버에서 자동으로 삭제됩니다.
          </p>
          <p className="mb-1">
            ② '회원'이 송신 또는 수신한 메시지의 백업은 '회원'이 직접 실시해야
            합니다. '도서관'은 센스월드의 메시지의 백업을 실시할 의무를 갖지
            않습니다.
          </p>
          <p className="mb-1">
            ③ '도서관'은 '회원'이 센스월드에 송신 또는 수신한 메시지에 대해
            백업이나 그에 상당하는 행위를 하지 않아 메시지의 손실이 발생한 경우
            책임을 지지 않습니다.
          </p>
          <p className="mb-4">
            ④ '회원'의 메시지 백업 도중 발생한 메시지의 변형 및 삭제에 대해서는
            '도서관'은 책임을 지지 않습니다.
          </p>

          <p className="mb-4">제 22 조 (책임제한)</p>

          <p className="mb-1">
            ① '도서관'은 천재지변 또는 이에 준하는 불가항력으로 인하여
            '서비스'를 제공할 수 없는 경우에는 '서비스' 제공에 관한 책임이
            면제됩니다.
          </p>
          <p className="mb-1">
            ② '도서관'은 '회원'의 귀책사유로 인한 '서비스' 이용의 장애에
            대하여는 책임을 지지 않습니다.
          </p>
          <p className="mb-1">
            ③ '도서관'은 '회원'이 '서비스'와 관련하여 게재한 정보, 자료, 사실의
            신뢰도, 정확성 등의 내용에 관하여는 책임을 지지 않습니다.
          </p>
          <p className="mb-1">
            ④ '도서관'은 '정회원'이 개인 드라이브 등에 등록한 '자료'에 대해
            자료백업이나 그에 상당하는 행위를 하지 않아 '자료'의 손실이 발생한
            경우 책임을 지지 않습니다.
          </p>
          <p className="mb-1">
            ⓞ '도서관'은 '회원' 간 또는 '회원'과 제3자 상호간에 '서비스'를
            매개로 하여 거래 등을 한 경우에는 책임이 면제됩니다.
          </p>
          <p className="mb-4">
            ⑥ '도서관'은 무료로 제공되는 서비스 이용과 관련하여 관련법에 특별한
            규정이 없는 한 책임을 지지 않습니다.
          </p>

          <p className="mb-4">제 23 조 (준거법 및 재판관할)</p>

          <p className="mb-1">
            ① '도서관'과 '회원' 간 제기된 소송은 대한민국법을 준거법으로 합니다.
          </p>
          <p className="mb-1">
            ② '도서관'과 '회원'간 발생한 분쟁에 관한 소송은 제소 당시의 '회원'의
            주소에 의하고, 주소가 없는 경우 거소를 관할하는 지방법원의
            전속관할로 합니다. 단, 제소 당시 '회원'의 주소 또는 거소가 명확하지
            아니한 경우의 관할법원은 민사소송법에 따라 정합니다.
          </p>
          <p className="mb-4">
            ③ 해외에 주소나 거소가 있는 '회원'의 경우 '도서관'과 '회원'간 발생한
            분쟁에 관한 소송은 전항에도 불구하고 대한민국 서울중앙지방법원을
            관할법원으로 합니다.
          </p>

          <p className="mb-4">부칙</p>
          <p className="mb-1">
            ① 이 약관은 서비스의 이용 시에 도서관이 안내하고 회원이 동의를
            함으로써 효력이 발생합니다. 회원은 서비스를 이용함으로써 센스월드
            서비스 이용약관에 동의함으로 간주됩니다.
          </p>
          <p className="mb-4">② 이 약관은 2020년 05월 06일부터 적용됩니다.</p>
        </div>{" "}
      </div>
    </>
  );
}
