import axios from "axios";
import { useQuery } from "@tanstack/react-query";

import { useLocation, useNavigate, useParams } from "react-router-dom";

import { printTime } from "../lib/print_text";

import { useRecoilValue } from "recoil";
import { useEffect, useState } from "react";

import { idAtom, tokenAtom } from "../lib/atom";
import api from "../lib/api";
import { ClockIcon, EyeIcon, PaperClipIcon } from "@heroicons/react/24/outline";
import { BackButton, DeleteButton, FnButton } from "../components/Button";
import ReplyList from "./ReplyList";
import BoardItemEdit from "./BoardItemEdit";
import { api_address } from "../lib/api_address";

export default function BoardItem() {
  const location = useLocation();
  const params = useParams();
  const navigate = useNavigate();
  const id = useRecoilValue(idAtom);

  const accessToken = useRecoilValue(tokenAtom);
  const [editable, setEditable] = useState();

  const { isLoading, error, data, refetch } = useQuery({
    queryKey: ["selectBoardItem", params.id],
    queryFn: () =>
      axios
        .post(
          `/api/readTalkPageItem/${params.id}`,
          {},
          {
            headers: {
              Authorization: "bearer " + api.getToken(),
            },
          }
        )
        .then((res) => {
          return res.data.result;
        }),
  });

  const fileList = data?.itemContent?.files?.files?.map((item, index) => (
    <div
      key={index}
      tabIndex={0}
      role="button"
      className="pb-2 group hover:bg-stone-50 last:pb-0.5 dark:hover:bg-transparent"
      onClick={() =>
        downFile(
          `/api/downloadTalkPageItem/${params.id}?comment=false&index=${index}`,
          item.name
        )
      }
    >
      <PaperClipIcon className="h-4  text-gray-500 mr-2 inline-block dark:text-gray-300" />
      <span className="p-1 text-sm text-gray-500 cursor-pointer hover:text-blue-500 hover:font-bold dark:text-gray-300">
        {item.name}
      </span>
    </div>
  ));

  const onEdit = () => {
    setEditable((prev) => !prev);
  };

  const onRemove = () => {
    if (window.confirm("게시물을 삭제하시겠습니까?")) {
      axios
        .post(
          `/api/deleteTalkPageItem/${params.id}`,
          {},
          {
            headers: {
              Authorization: "bearer " + api.getToken(),
            },
          }
        )
        .then((res) => {
          window.alert(`게시물이 삭제되었습니다.`);
          navigate(-1);
        })
        .catch((res) => console.log(res));
    }
  };

  const downFile = (addr, name) => {
    // axios
    //   .post(
    //     addr,
    //     { responseType: "blob" },
    //     {
    //       headers: {
    //         Authorization: "bearer " + api.getToken(),
    //         "Content-Type": `application/octet-stream`,
    //       },
    //     }
    //   )
    //   .then((res) => {
    //     // console.log(res);
    //     const blob = new Blob([res.data]);
    //     const fileUrl = window.URL.createObjectURL(blob);
    //     const link = document.createElement("a");
    //     link.href = fileUrl; // get access_token 주소를 넣고 remove
    //     link.style.display = "none";
    //     link.download = name;

    //     document.body.appendChild(link);
    //     link.click();
    //     link.remove();
    //   })
    //   .catch((res) => console.log(res));

    const link = document.createElement("a");
    link.href = `${api_address}${addr}&access_token=${api.getToken()}`;
    link.style.display = "none";
    link.download = name;
    document.body.appendChild(link);
    link.click();
    link.remove();
  };

  const [admin, setAdmin] = useState();

  useEffect(() => {
    setAdmin(location?.state?.admin);
    // console.log(params);
  }, []);

  return (
    <>
      {isLoading ? (
        "loading..."
      ) : error ? (
        <div>Error: {error.message}</div>
      ) : editable && accessToken ? (
        <>
          <div className="flex mb-10">
            <h1 className="text-2xl sm:text-3xl font-bold text-stone-900 dark:text-stone-200 ">
              {data.talkPage.name}
            </h1>
          </div>
          <BoardItemEdit
            data={data}
            setEditable={setEditable}
            refetch={refetch}
            attribute={data.talkPage.attributes}
          />
        </>
      ) : (
        <>
          <div className="flex mb-10">
            <h1 className="text-2xl sm:text-3xl font-bold text-stone-900 dark:text-stone-200 ">
              {data.talkPage.name}
            </h1>
          </div>
          <table className="my-4 mx-auto w-full dark:text-gray-300">
            <thead className="bg-gray-50 dark:bg-slate-800">
              <tr className="border-b border-stone-200 dark:border-gray-400/20 bg-stone-200 dark:bg-gray-700">
                <th colSpan="3" className="px-4 py-3 text-lg text-left">
                  {data.title}
                </th>
              </tr>
              <tr className="border-b border-gray-200 dark:border-gray-400/20 text-sm">
                <th className="px-4 py-2  font-normal flex justify-between items-center">
                  <span className="">{data.name}</span>

                  <span>
                    <ClockIcon className="h-4  text-gray-500 mr-2 inline-block" />
                    {printTime(data.createTime, true)}
                  </span>
                  <span>
                    <EyeIcon className="h-4  text-gray-500 mr-2 inline-block" />
                    {data.count.readCount}
                  </span>
                </th>
              </tr>
            </thead>
            <tbody className="contents">
              <tr className="">
                <td
                  className="p-4 border-b border-gray-200 dark:border-gray-400/20 whitespace-pre-wrap"
                  colSpan="3"
                >
                  {data.itemContent.content}
                </td>
              </tr>
              {data?.itemContent?.files?.files && (
                <tr>
                  <td
                    className="p-4 border-b border-gray-200 text-sm text-gray-500 cursor-pointer hover:bg-stone-50 dark:hover:bg-gray-700 "
                    colSpan="3"
                  >
                    {fileList}
                  </td>
                </tr>
              )}
            </tbody>
            <tfoot>
              {location?.state?.attributes?.includes("COMMENT") && (
                <ReplyList
                  id={params.id}
                  attribute={data.talkPage.attributes}
                  admin={admin}
                />
              )}
            </tfoot>
          </table>

          <div className="block">
            <FnButton
              text={`목록 보기`}
              onClick={() => {
                navigate(
                  `/channel/${params.channelId}/${params.boardId}?page=1&size=10`
                );
              }}
            />
            <div className="flex">
              {!!accessToken && (
                <div className="flex ml-auto space-x-4">
                  {data.userId === id && (
                    <>
                      <FnButton text={`수정`} onClick={onEdit} />
                    </>
                  )}
                  {(data.userId === id || admin) && (
                    <>
                      <DeleteButton text={`삭제`} onClick={onRemove} />
                    </>
                  )}
                </div>
              )}
            </div>
          </div>
        </>
      )}
    </>
  );
}
