import axios from "axios";
import qs from "query-string";
import api from "../lib/api";

import { Link } from "react-router-dom";
import { useQuery } from "@tanstack/react-query";
import { idAtom } from "../lib/atom";
import { useRecoilValue } from "recoil";
import { BackButton } from "../components/Button";

export default function ChannelList() {
  const id = useRecoilValue(idAtom);

  const { isLoading, error, data, refetch } = useQuery({
    queryKey: [`MyChannel`],
    queryFn: () =>
      axios
        .post(
          `/api/getMyTalkList`,
          qs.stringify({
            type: "CUSTOM",
          }),
          {
            headers: {
              Authorization: "bearer " + api.getToken(),
            },
          }
        )
        .then((res) => {
          return res.data.result;
        }),
  });

  const MyChannelList = data?.content?.map((item, index) => (
    <div key={`my ` + index}>
      {item.user.id === id && (
        <Link
          key={item.id}
          to={`/channel/${item.id}`}
          state={{ channelTitle: item.title, admin: item.admin }}
        >
          <li className="text-lg my-2 hover:bg-yellow-300 w-full p-2 rounded-lg  hover:font-bold dark:hover:text-black">
            {item.title}
          </li>
        </Link>
      )}
    </div>
  ));

  const SubChannelList = data?.content?.map((item, index) => (
    <div key={`sub ` + index}>
      {item.user.id !== id && (
        <Link
          to={`/channel/${item.id}`}
          state={{ channelTitle: item.title, admin: item.admin }}
        >
          <li className="text-lg my-2 hover:bg-yellow-300 w-full p-2 rounded-lg hover:font-bold dark:hover:text-black">
            {item.title}
          </li>
        </Link>
      )}
    </div>
  ));

  const blueText = ` text-blue-600 dark:text-white `;
  const blueBg = ` border-blue-600 hover:bg-blue-600 hover:text-white dark:bg-blue-600 dark:hover:bg-blue-700 dark:hover:border-blue-700`;
  return (
    <>
      {isLoading ? (
        "loading..."
      ) : error ? (
        <div>Error: {error.message}</div>
      ) : (
        <>
          <div className="w-full sm:w-3/4 sm:mx-auto mt-24">
            {/* <div className="my-16 text-2xl font-bold text-center text-blue-600">
               가입 채널 
            </div> */}

            <div className="sm:flex mx-auto sm:space-x-8 dark:text-white">
              <div className="sm:w-1/2  bg-gray-100 dark:bg-gray-700 rounded-lg p-8 ">
                <div className="mb-8 text-xl font-bold text-center">
                  내 채널
                </div>
                <ul className=" list-inside">
                  {MyChannelList.length !== 0 ? (
                    MyChannelList
                  ) : (
                    <div className="text-center">채널이 존재하지 않습니다.</div>
                  )}
                </ul>
              </div>
              <div className="mt-8 sm:mt-0 sm:w-1/2 bg-gray-100 dark:bg-gray-700 rounded-lg p-8">
                <div className="mb-8 text-xl font-bold text-center">
                  가입 채널
                </div>
                <ul className=" list-inside">
                  {SubChannelList.length !== 0 ? (
                    SubChannelList
                  ) : (
                    <div className="text-center">채널이 존재하지 않습니다.</div>
                  )}
                </ul>
              </div>
            </div>
            <div className="text-center mt-24">
              <Link
                to="search"
                className={`border-2 p-2 px-4 rounded-md ` + blueBg + blueText}
              >
                채널 검색
              </Link>
            </div>
          </div>

          {/* <div className=" mx-auto flex lg:w-3/4 mt-8">
            <div className="px-8 p-2 mr-auto lg:px-0">
              <BackButton />
            </div>
          </div> */}
        </>
      )}
    </>
  );
}
