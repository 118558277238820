export default function Privacy() {
  return (
    <>
      <div className="w-full sm:w-3/4 sm:mx-auto mt-24">
        <div className="my-16 text-2xl font-bold text-center text-blue-600 dark:text-blue-500">
          개인정보처리방침
        </div>
        <div className="p-4 lg:w-1/2 mx-auto dark:text-gray-300">
          (주)엑스비전테크놀로지(이하 '회사')는 정보주체의 자유와 권리 보호를
          위해 '개인정보 보호법' 및 관계 법령이 정한 바를 준수하여, 적법하게
          개인정보를 처리하고 안전하게 관리하고 있습니다. 이에 '개인정보 보호법'
          제30조에 따라 정보주체에게 개인정보 처리에 관한 절차 및 기준을
          안내하고, 이와 관련한 고충을 신속하고 원활하게 처리할 수 있도록 하기
          위하여 다음과 같이 개인정보 처리방침을 수립·공개합니다.
          <p className="mb-4 mt-8">제1조 개인정보의 처리목적</p>
          <p className="mb-4">
            회사는 다음의 목적을 위하여 개인정보를 처리합니다. 처리하고 있는
            개인정보는 다음의 목적 이외의 용도로는 이용되지 않으며, 이용 목적이
            변경되는 경우에는 '개인정보 보호법' 제18조에 따라 별도의 동의를 받는
            등 필요한 조치를 이행할 예정입니다.
          </p>
          <p className="mb-4">
            ① 회원 가입 및 관리
            <br />
            회원 가입의사 확인, 회원제 서비스 제공에 따른 본인 식별·인증,
            회원자격 유지·관리, 서비스 부정이용 방지, 만 14세 미만 아동의
            개인정보 처리 시 법정대리인의 동의여부 확인, 각종 고지·통지,
            고충처리, 접속 빈도 파악 또는 회원의 서비스 이용에 대한 통계
            목적으로 개인정보를 처리합니다.
          </p>
          <p className="mb-4">
            ② 서비스 제공
            <br />
            서비스 제공, 콘텐츠 제공, 본인인증, 연령인증의 목적으로 개인정보를
            처리합니다.
          </p>
          <p className="mb-4 mt-8">제2조 개인정보의 처리 및 보유 기간</p>
          <p className="mb-4">
            ① 회사는 법령에 따른 개인정보 보유·이용기간 또는 정보주체로부터
            개인정보를 수집 시에 동의 받은 개인정보 보유·이용기간 내에서
            개인정보를 처리·보유합니다.
          </p>
          <p className="mb-4">
            ② 개인정보 처리 및 보유 기간은 다음과 같습니다.
          </p>
          <p className="mb-4">
            1. 센스월드 회원 가입 및 관리 : 아이디 탈퇴 시까지
            <br />
            다만, 관계 법령 위반에 따른 수사·조사 등이 진행 중인 경우에는 해당
            수사·조사 종료 시까지
          </p>
          <p className="mb-4 mt-8">
            제3조 수집·처리하는 개인정보의 항목
            <br />
            회사는 다음의 개인정보 항목을 수집·처리하고 있습니다.
          </p>
          <p className="mb-4">
            ① 센스월드 회원 가입 및 관리(링크애니 서비스)
            <br />- 필수항목 : 성명, 전화번호, 이메일 주소
          </p>
          <p className="mb-4">
            ② IT로 도서관 서비스 제공(리드애니 서비스)
            <br />- 필수항목 : 생년월일, 성별, 나이, 주소
          </p>
          <p className="mb-4 mt-8">
            제4조 만 14세 미만 아동의 개인정보 처리에 관한 사항
          </p>
          <p className="mb-4">
            ① 회사는 만 14세 미만 아동에 대해 개인정보를 수집할 때 법정대리인의
            동의를 얻어 해당 서비스 수행에 필요한 최소한의 개인정보를
            수집합니다.
            <br />- 필수항목 : 법정 대리인의 성명, 관계, 연락처
          </p>
          <p className="mb-4">
            ② 회사는 만 14세 미만 아동의 개인정보를 수집할 때에는 아동에게
            법정대리인의 성명, 연락처와 같이 최소한의 정보를 요구할 수 있으며,
            다음 중 하나의 방법으로 적법한 법정대리인이 동의하였는지를
            확인합니다.
            <br />- 동의 내용이 적힌 서면을 법정대리인에게 직접 발급하거나, 우편
            또는 팩스를 통하여 전달하고 법정대리인이 동의 내용에 대하여 서명날인
            후 제출하도록 하는 방법
            <br />- 동의 내용이 적힌 전자우편을 발송하여 법정대리인으로부터
            동의의 의사표시가 적힌 전자우편을 전송받는 방법
            <br />- 전화를 통하여 동의 내용을 법정대리인에게 알리고 동의를 얻는
            방법
            <br />- 그 밖에 위와 준하는 방법으로 법정대리인에게 동의 내용을
            알리고 동의의 의사표시를 확인하는 방법
          </p>
          <p className="mb-4 mt-8">
            제5조 개인정보의 파기 절차 및 방법에 관한 사항
          </p>
          <p className="mb-4">
            ① 회사는 개인정보 보유기간의 경과, 처리목적 달성 등 개인정보가
            불필요하게 되었을 때에는 지체없이 해당 개인정보를 파기합니다.
          </p>
          <p className="mb-4">
            ② 정보주체로부터 동의받은 개인정보 보유기간이 경과하거나 처리목적이
            달성되었음에도 불구하고 다른 법령에 따라 개인정보를 계속 보존하여야
            하는 경우에는, 해당 개인정보를 별도의 데이터 베이스(DB)로 옮기거나
            보관장소를 달리하여 보존합니다.
          </p>
          <p className="mb-4">
            ③ 개인정보 파기의 절차 및 방법은 다음과 같습니다.
          </p>
          <p className="mb-4">
            1. 파기절차
            <br />
            회사는 파기 사유가 발생한 개인정보를 선정하고, 회사의 개인 정보
            보호책임자의 승인을 받아 개인정보를 파기합니다.
          </p>
          <p className="mb-4">
            2. 파기방법
            <br />
            회사는 전자적 파일 형태로 기록·저장된 개인정보는 기록을 재생할 수
            없도록 파기하며, 종이 문서에 기록·저장된 개인정보는 분쇄기로
            분쇄하거나 소각하여 파기합니다.
          </p>
          <p className="mb-4 mt-8">
            제6조 정보주체와 법정대리인의 권리·의무 및 행사방법에 관한 사항
          </p>
          <p className="mb-4">
            ① 정보주체는 회사에 대해 언제든지 개인정보 열람·정정·삭제·처리정지
            요구 등의 권리를 행사할 수 있습니다.
            <br />※ 만 14세 미만 아동에 관한 개인정보의 열람등 요구는
            법정대리인이 직접 해야 하며, 만 14세 이상의 미성년자인 정보주체는
            정보주체의 개인정보에 관하여 미성년자 본인이 권리를 행사하거나
            법정대리인을 통하여 권리를 행사할 수도 있습니다.
          </p>
          <p className="mb-4">
            ② 권리 행사는 회사에 대해 '개인정보 보호법' 시행령 제41조 제1항에
            따라 서면, 전자우편, 모사전송(FAX) 등을 통하여 하실 수 있으며,
            회사는 이에 대해 지체없이 조치해야 합니다.
          </p>
          <p className="mb-4">
            ③ 권리 행사는 정보주체의 법정대리인이나 위임을 받은 자 등 대리인을
            통하여 하실 수도 있습니다.
            <br />이 경우 “개인정보 처리 방법에 관한 고시(제2020-7호)” 별지
            제11호 서식에 따른 위임장을 제출하셔야 합니다.
          </p>
          <p className="mb-4">
            ④ 개인정보 열람 및 처리정지 요구는 '개인정보 보호법' 제35조 제4항,
            제37조 제2항에 의하여 정보주체의 권리가 제한 될 수 있습니다.
          </p>
          <p className="mb-4">
            ⑤ 개인정보의 정정 및 삭제 요구는 다른 법령에서 그 개인정보가 수집
            대상으로 명시되어 있는 경우에는 그 삭제를 요구할 수 없습니다.
          </p>
          <p className="mb-4">
            ⑥ 회사는 정보주체 권리에 따른 열람의 요구, 정정·삭제의 요구,
            처리정지의 요구 시 열람 등 요구를 한 자가 본인이거나 정당한
            대리인인지를 확인합니다.
          </p>
          <p className="mb-4 mt-8">
            제7조 개인정보의 안전성 확보조치에 관한 사항
          </p>
          <p className="mb-4">
            회사는 개인정보의 안전성 확보를 위해 다음과 같은 조치를 취하고
            있습니다.
          </p>
          <p className="mb-4">
            ① 기술적 조치 : 개인정보처리시스템 등의 접근권한 관리,
            접근통제시스템 설치, 개인정보의 암호화, 보안프로그램 설치 및 갱신
          </p>
          <p className="mb-4">
            ② 물리적 조치 : 전산실, 자료보관실 등의 접근통제
          </p>
          <p className="mb-4 mt-8">제8조 개인정보 보호책임자에 관한 사항</p>
          <p className="mb-4">
            ① 회사는 개인정보 처리에 관한 업무를 총괄해서 책임지고, 개인정보
            처리와 관련한 정보주체의 불만처리 및 피해구제 등을 위하여 아래와
            같이 개인정보보호책임자를 지정하고 있습니다.
            <br />- 개인정보보호책임자
            <br />
            담당자 : 최민준
            <br />
            직책 : 부장
            <br />
            연락처 : 02-888-1623{" "}
          </p>
          <p className="mb-4">
            ② 정보주체는 회사의 서비스를 이용하면서 발생한 모든 개인정보보호
            관련 문의, 불만처리, 피해구제 등에 관한 사항을 개인정보 보호책임자
            및 담당부서로 문의할 수 있습니다.
            <br />
            회사는 정보주체의 문의에 대해 지체없이 답변 및 처리해야 합니다.
          </p>
          <p className="mb-4 mt-8">
            제9조 개인정보의 열람청구를 접수·처리하는 부서
          </p>
          <p className="mb-4">
            정보주체는 '개인정보 보호법' 제35조에 따른 개인정보의 열람 청구를
            아래의 부서에 할 수 있습니다.
            <br />
            회사는 정보주체의 개인정보 열람청구가 신속하게 처리되도록 노력해야
            합니다.
            <br />- 개인정보 열람청구 접수·처리 부서
            <br />
            부서명 : 디지털접근팀
            <br />
            담당자 : 최민준
            <br />
            연락처 : 02-888-1623
          </p>
          <p className="mb-4">제10조 정보주체의 권익침해에 대한 구제방법</p>
          <p className="mb-4">
            ① 정보주체는 개인정보침해로 인한 구제를 받기 위하여
            개인정보분쟁조정위원회, 한국인터넷진흥원 개인정보침해신고센터 등에
            분쟁해결이나 상담 등을 신청할 수 있습니다.
            <br />이 밖에 기타 개인정보침해의 신고, 상담에 대하여는 아래의
            기관에 문의하시기 바랍니다.
            <br />
            1. 개인정보분쟁조정위원회 : (국번없이) 1833-6972 (www.kopico.go.kr)
            <br />
            2. 개인정보침해신고센터 : (국번없이) 118 (privacy.kisa.or.kr)
            <br />
            3. 대검찰청 : (국번없이) 1301 (www.spo.go.kr)
            <br />
            4. 경찰청 : (국번없이) 182 (ecrm.cyber.go.kr)
          </p>
          <p className="mb-4">
            ② 회사는 정보주체의 개인정보자기결정권을 보장하고, 개인정보침해로
            인한 상담 및 피해 구제를 위해 노력하고 있으며, 신고나 상담이 필요한
            경우 아래의 담당부서로 연락해 주시기 바랍니다.
            <br />- 개인정보보호 관련 고객 상담 및 신고
            <br />
            부서명 : 디지털접근팀
            <br />
            담당자 : 최민준
            <br />
            연락처 : 02-888-1623
          </p>
          <p className="mb-4">제11조 개인정보 처리방침의 변경에 관한 사항</p>
          <p className="mb-4">
            ① 이 개인정보 처리방침은 2023년 12월 27일 부터 적용됩니다.
          </p>
        </div>
      </div>
    </>
  );
}
